import { IComponent } from '../component.interface';
import * as Hammer from 'hammerjs';
import './slider01.scss';
import template from './slider01.html';
import { debounce } from '../../helpers/debounce';

export class Slider01 {
    private viewEl: HTMLElement;
    private panelEl: HTMLElement;
    private prevButton: Element;
    private nextButton: Element;
    private indicatorContainerEL: Element;

    private currentItem = 0;
    private slideCount = 0;
    private slideWidth = 0;
    private lastPosition = 0;

    private indicatorMap = new Map<number, Element>();
    private slideMap = new Map<number, Element>();

    private intervalRef: number | null = null;

    private disableTranstionClass = 'disable-transition';

    constructor(private hostElement: Element, private autoplay = false, private autoplayInterval = 3000) {
        // Query elements beneath the hostElement
        this.viewEl = hostElement.getElementsByClassName('slider01-view')[0] as HTMLElement;
        this.panelEl = hostElement.getElementsByClassName('slider01-panel')[0] as HTMLElement;
        this.prevButton = hostElement.getElementsByClassName('slider01-action-prev')[0];
        this.nextButton = hostElement.getElementsByClassName('slider01-action-next')[0];
        this.indicatorContainerEL = hostElement.getElementsByClassName('slider01-indicator-container')[0];

        const slides = Array.from(hostElement.getElementsByClassName('slide01'));
        this.slideCount = slides.length - 1;
        slides.forEach((n, i) => this.slideMap.set(i, n));

        // Render Indicators if the container is present
        if (!!this.indicatorContainerEL) {
            this.renderIndicators();
        }

        this.setStateClasses(this.currentItem);

        // Set slide width after next cycle ot ensure rendering is done
        setTimeout(() => (this.slideWidth = this.viewEl.clientWidth), 0);

        // Start Autoplay if enables
        if (this.autoplay) {
            this.intervalRef = <any>setInterval(() => {
                if (this.currentItem + 1 > this.slideCount) {
                    this.switchTo(0);
                } else {
                    this.switchTo(this.currentItem + 1);
                }
            }, this.autoplayInterval);
        }

        // Add Event Listeners to window resize to update the slide with,
        var resizeEnd = debounce(() => {
            this.hostElement.classList.remove(this.disableTranstionClass);
        }, 0);

        window.addEventListener('resize', () => {
            this.slideWidth = this.viewEl.clientWidth;
            this.hostElement.classList.add(this.disableTranstionClass);
            this.switchTo(this.currentItem);
            resizeEnd();
        });

        // Add Event Listeners to Next and Previous Buttons if they exist
        if (!!this.prevButton) {
            this.prevButton.addEventListener('click', () => this.prev());
        }

        if (!!this.nextButton) {
            this.nextButton.addEventListener('click', () => this.next());
        }

        // Add Touch Geatures
        const panelHammer = new Hammer(this.viewEl);
        panelHammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
        panelHammer.on('swipeleft', event => this.swipeNext(event));
        panelHammer.on('swiperight', event => this.swipePrev(event));
        panelHammer.on('panmove', event => this.panMove(event));
        panelHammer.on('panend', event => this.panEnd(event));
        panelHammer.on('pancancel', () => this.panCancel());
    }

    private switchTo(item: number) {
        if (item < 0) {
            item = 0;
        } else if (item > this.slideCount) {
            item = this.slideCount;
        }

        this.lastPosition = -Math.floor(item * this.slideWidth);
        this.translateX(this.lastPosition);
        this.currentItem = item;
        this.setStateClasses(this.currentItem);
    }

    public prev() {
        this.clearAutoplay();
        const slide = Math.round(this.currentItem - 1);
        this.switchTo(slide);
    }

    public next() {
        this.clearAutoplay();
        const slide = Math.round(this.currentItem + 1);
        this.switchTo(slide);
    }

    private swipePrev($event: HammerInput) {
        this.clearAutoplay();

        $event.srcEvent.stopPropagation();
        const slide = Math.round(this.currentItem - 1);
        this.switchTo(slide);
    }

    private swipeNext($event: HammerInput) {
        this.clearAutoplay();

        $event.srcEvent.stopPropagation();
        const slide = Math.round(this.currentItem + 1);
        this.switchTo(slide);
    }

    private panMove($event: HammerInput) {
        this.clearAutoplay();

        if (!this.hostElement.classList.contains(this.disableTranstionClass)) {
            this.hostElement.classList.add(this.disableTranstionClass);
        }

        // this.panelEl.style.transform = `translateX(${}px)`;
        this.translateX(this.lastPosition + $event.deltaX);
    }

    private panEnd($event: HammerInput) {
        this.hostElement.classList.remove(this.disableTranstionClass);

        // Find out which slide to stick to;
        const slidesMoved =
            Math.abs($event.deltaX % this.slideWidth) > this.slideWidth / 2
                ? Math.ceil(Math.abs($event.deltaX / this.slideWidth)) * ($event.deltaX > 0 ? 1 : -1)
                : Math.floor(Math.abs($event.deltaX / this.slideWidth)) * ($event.deltaX > 0 ? 1 : -1);

        const slide = this.currentItem + slidesMoved * -1;
        this.switchTo(slide);
    }

    private panCancel() {
        this.hostElement.classList.remove(this.disableTranstionClass);
        this.switchTo(this.currentItem);
    }

    private translateX(x: number) {
        if ('requestAnimationFrame' in window) {
            window.requestAnimationFrame(() => {
                this.panelEl.style.transform = `translateX(${x}px)`;
            });
        } else {
            this.panelEl.style.transform = `translateX(${x}px)`;
        }
    }

    private setStateClasses(index: number) {
        // set is-active class for indicators
        this.indicatorMap.forEach((el, key) => {
            if (key === index && !el.classList.contains('is-active')) {
                el.classList.add('is-active');
            }
            if (key !== index && el.classList.contains('is-active')) {
                el.classList.remove('is-active');
            }
        });

        // set is-active class for slides
        this.slideMap.forEach((el, key) => {
            if (key === index && !el.classList.contains('is-active')) {
                el.classList.add('is-active');
            }
            if (key !== index && el.classList.contains('is-active')) {
                el.classList.remove('is-active');
            }
        });

        // set is-disabled class for previous button
        if (!!this.prevButton) {
            if (index === 0 && !this.prevButton.classList.contains('is-disabled')) {
                this.prevButton.classList.add('is-disabled');
            }
            if (index !== 0 && this.prevButton.classList.contains('is-disabled')) {
                this.prevButton.classList.remove('is-disabled');
            }
        }

        // set is-disabled class for next button
        if (!!this.nextButton) {
            if (index === this.slideCount && !this.nextButton.classList.contains('is-disabled')) {
                this.nextButton.classList.add('is-disabled');
            }
            if (index !== this.slideCount && this.nextButton.classList.contains('is-disabled')) {
                this.nextButton.classList.remove('is-disabled');
            }
        }
    }

    private renderIndicators() {
        const slideCountArr = Array.from(Array(this.slideCount + 1).keys());
        slideCountArr.forEach(i => {
            const el = document.createElement('SPAN');
            el.classList.add('slider01-indicator');
            // el.innerText = i.toString(); // add indicator is possible
            el.addEventListener('click', () => this.switchTo(i));
            this.indicatorContainerEL.appendChild(el);
            this.indicatorMap.set(i, el);
        });
    }

    private clearAutoplay() {
        if (!!this.intervalRef) {
            clearInterval(<any>this.intervalRef);
            this.intervalRef = null;
        }
    }
}

export const slider01Component = <IComponent>{
    //template: template,
    init: (templateRoot = []) => {
        //templateRoot = document.querySelectorAll('.slider01');
        //templateRoot.forEach(n => new Slider01(n));
        document.querySelectorAll('.slider01').forEach(n => new Slider01(n));
    }
};
