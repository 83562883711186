// main entry for scss
import './styles.scss';

// polyfills
import './polyfills';
// // import "@babel/polyfill";


//Array.from(document.querySelectorAll('.js-sider-x')).forEach(element => new Hest(element));
// main (native javascript) applications

import { ComponentContainer } from './components/component-container';

import { Navigation } from './components/navigation/navigation';
import { textStandardComponent } from './components/text-standard/text-standard';
import { responsiveImagesComponent } from './components/responsive-images/responsive-images';
import { grid01Component } from './components/grid01/grid01';
import { Article } from './components/text-image/text-image';
import { grid02Component } from './components/grid02/grid02';
import { Slider01 } from './components/slider01/slider01';
import { Slider02 } from './components/slider02/slider02';
import { Form } from './components/forms/forms';
import { alertsComponent } from './components/alerts/alerts';
import { youtubeComponent } from './components/video/youtube/youtube';
import { vimeoComponent } from './components/video/vimeo/vimeo';
import { Banner } from './components/banner/banner';

// bootstrap main App when DOMContentLOADED
document.addEventListener('DOMContentLoaded', () => {

    //document.querySelectorAll('.slider01').forEach(n => new Slider01(n));


    //const container = <HTMLElement>document.getElementById('components');
    //const header = <HTMLElement>document.getElementById('header');

    //new ComponentContainer(header, [
    //    navigationComponent
    //]);

    //new ComponentContainer(container, [
    //    vimeoComponent,
    //    youtubeComponent,
    //    //slider01Component,
    //    slider02Component,
    //    bannerComponent,
    //    textStandardComponent,
    //    formsComponent,
    //    alertsComponent,
    //    grid02Component,
    //    grid01Component,
    //    textImageComponent,
    //    responsiveImagesComponent,
    //]);

    document.querySelectorAll('.nav').forEach(n => new Navigation());
    document.querySelectorAll('.banner').forEach(n => new Banner());
    document.querySelectorAll('.slider01').forEach(n => new Slider01(n));
    document.querySelectorAll('.slider02').forEach(n => new Slider02(n));
    document.querySelectorAll('.form').forEach(n => new Form());
    document.querySelectorAll('.main').forEach(n => new Article());

    // const body = document.getElementsByTagName('BODY')[0];
    // angular.bootstrap(body, [ngModule.name]);
});

// // ServiceWorker is a progressive technology. Ignore unsupported browsers
// if ('serviceWorker' in navigator) {
//     console.log('CLIENT: service worker registration in progress.');
//     navigator.serviceWorker.register('service-worker.js').then(
//         function() {
//             console.log('CLIENT: service worker registration complete.');
//         },
//         function() {
//             console.log('CLIENT: service worker registration failure.');
//         }
//     );
// } else {
//     console.log('CLIENT: service worker is not supported.');
// }
