import './navigation.scss';
//import template from './navigation.html';

import { IComponent } from './../component.interface';
import { Toggle } from '../../helpers/toggle';

export class Navigation {
    private isActive = false;
    private scrolly = 0;

    constructor() {
        var body = document.getElementsByTagName('BODY')[0];

        // Get and bind selectors for toggling secondary level
        document.querySelectorAll('[data-level-toggle]').forEach(n => {
            const targetSelector = <string>n.getAttribute('data-level-toggle');
            const targets = document.querySelectorAll(targetSelector);
            new Toggle(n, targets, 'is-active');
        });

        // Get Primary element for toggling mobile navigation
        const navToggle = <HTMLElement>document.getElementById('js-nav-toggle');
        const navToggleTarget = <HTMLElement>document.getElementById('js-nav-toggle-target');

        if (!!navToggle) {
            navToggle.addEventListener('click', _event => {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    navToggle.classList.add('is-active');
                    navToggleTarget.classList.add('is-active');
                    this.scrolly = window.scrollY;
                    body.classList.add('has-overlay');
                } else {
                    navToggle.classList.remove('is-active');
                    navToggleTarget.classList.remove('is-active');
                    body.classList.remove('has-overlay');
                    window.scrollTo(0, this.scrolly); // values are x,y-offset
                }
            });
        }
    }
}

export const navigationComponent = <IComponent>{
    //template: template,
    init: () => new Navigation()
};
