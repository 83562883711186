/**
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/weak-map';
// import 'core-js/es6/set';

// https://babeljs.io/docs/en/caveats
import 'classlist-polyfill';

// import 'core-js/modules/web.dom.iterable';
// import 'core-js/es6/promise';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT.
// import 'core-js/es7/reflect';

/**
 * Required to support Web Animations .
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * APPLICATION IMPORTS
 */

/// IE requires the following for responsive images */
// https://github.com/scottjehl/picturefill
// $ npm install --save picturefill
import 'picturefill';

// Required for .forEach on NodeList (etc. querySelectorAll('..').forEach)
// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
if ((<any>window).NodeList && !NodeList.prototype.forEach) {
    (<any>NodeList).prototype.forEach = Array.prototype.forEach;
}
