export class Toggle {
    public isActive = false;
    constructor(el: Element, targets: NodeListOf<Element>, className: string) {
        console.log(el, targets, className);

        el.addEventListener('click', _event => {
            this.isActive = !this.isActive;
            this.isActive ? el.classList.add(className) : el.classList.remove(className);
            this.isActive ? targets.forEach(n => n.classList.add(className)) : targets.forEach(n => n.classList.remove(className));
        });
    }
}
